import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from "./utils/echarts";
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from '../node_modules/element-ui/lib/locale/lang/en'
Vue.use(ElementUI, {
  locale
});
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 import VueClipboard from 'vue-clipboard2'
 
 Vue.use(VueClipboard)
Vue.use(VueQuillEditor)

Vue.directive('selectLoadMore',{
  bind (el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      if (this.scrollHeight - this.scrollTop < this.clientHeight + 1) {
        binding.value()
      }
    })
  }
})

import axios from 'axios'
import qs from 'qs'
axios.defaults.timeout = 500000000;                        //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';           //配置请求头
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
  //在发送请求之前做某件事
  if (localStorage.getItem('code') != undefined) {
    config.headers['access-token'] = localStorage.getItem('access_token');
  }
  if (config.method === 'post') {
    config.data = qs.stringify(config.data);
  }
  return config;
}, (error) => {
  // this.$message.error('参数异常');
  return Promise.reject(error);
});
Vue.prototype.$http = axios;
//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) => {
  //对响应数据做些事
  if (!res.data.success) {
    _.toast(res.data.msg);
    return Promise.reject(res);
  }
  return res;
}, (error) => {
  // console.log(error)
  // this.$message.error('网络异常');
  return Promise.reject(error);
});
import { fetchPost, fetchGet, fetchGetTwo, currentMonthDays, currentMonthDaysYMD } from "./PublicApi"
Vue.prototype.$fetchPost = fetchPost;
Vue.prototype.$fetchGet = fetchGet;
Vue.prototype.$fetchGetTwo = fetchGetTwo;
Vue.prototype.$getMonth = currentMonthDays;
Vue.prototype.$getMonthYMD = currentMonthDaysYMD;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
