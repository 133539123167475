
import axios from 'axios'
//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
////返回一个Promise(发送get请求)
export function fetchGet(url, param) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param })
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function fetchGetTwo(url, param) {
    return new Promise((resolve, reject) => {
        axios.get(url, param)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
// export function fetchGetTwo(url, param) {
//     return new Promise((resolve, reject) => {
//         axios.get(
// 		url,
// 		 param,
// 		 {headers: {'Content-Type': 'multipart/form-data'}})
//             .then(response => {
//                 resolve(response.data)
//             }, err => {
//                 reject(err)
//             })
//             .catch((error) => {
//                 reject(error)
//             })
//     })
// }
/**
* 获取当月的日期集合(yyyy-MM-dd)
*/


export function currentMonthDays(dateNow, intervalDays, bolPastTime) {
    let oneDayTime = 24 * 60 * 60 * 1000;
    let list = [];
    let lastDay;

    if (bolPastTime == true) {
        // lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
        for (let i = 0; i < intervalDays; i++) {
            list.push(formateDate(new Date(dateNow.getTime() - (intervalDays - i) * oneDayTime)
            ));
        }
        list.push(formateDate(dateNow));
    } else {
        // lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime);
        list.push(formateDate(dateNow));
        for (let i = 0; i < intervalDays; i++) {
            list.push(formateDate(new Date(dateNow.getTime() - (intervalDays + i) * oneDayTime)
            ));
        }
    }
    return list;
}
export function currentMonthDaysYMD(dateNow, intervalDays, bolPastTime) {
    let oneDayTime = 24 * 60 * 60 * 1000;
    let list = [];
    let lastDay;

    if (bolPastTime == true) {
        // lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
        for (let i = 0; i < intervalDays; i++) {
            list.push(formateDateYMD(new Date(dateNow.getTime() - (intervalDays - i) * oneDayTime)
            ));
        }
        list.push(formateDateYMD(dateNow));
    } else {
        // lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime);
        list.push(formateDateYMD(dateNow));
        for (let i = 0; i < intervalDays; i++) {
            list.push(formateDateYMD(new Date(dateNow.getTime() - (intervalDays + i) * oneDayTime)
            ));
        }
    }
    return list;
}
function formateDateYMD(time) {
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let day = time.getDate()

    if (month < 10) {
        month = '0' + month
    }

    if (day < 10) {
        day = '0' + day
    }
    return year + '-' + month + '-' + day + ''

}
function formateDate(time) {
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let day = time.getDate()

    if (month < 10) {
        month = '0' + month
    }

    if (day < 10) {
        day = '0' + day
    }
    let Mon = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
    ]
    return Mon[month - 1] + ' ' + day + ''

}