<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- <keep-alive include="indexMaster"> -->
    <transition name="fade" mode="in-out">
      <router-view />
    </transition>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
	export default{
		data(){
			return{
				urlList:[],
				logoPng:''
			}
		},
		mounted() {
			this.getData()
			this.getData2()
			this.getCarouselData()
		},
		methods:{
			getData(){
				this.$fetchGet("/cms-middle/cmsSite/config?type=column")
					.then((result) => {
						let a = JSON.stringify(result.config)
						localStorage.setItem('columnDetail',a)
					})
					.catch((err) => {});
			},
			getData2() {
				this.$fetchGet("/cms-middle/cmsSite/config?type=basic")
					.then((result) => {
						this.logoPng = process.env.VUE_APP_ENV + result.config.logo.description
						localStorage.setItem('logoPng',this.logoPng)
					})
					.catch((err) => {});
			},
			getCarouselData() {
			  this.$fetchGet("/cms-middle/cmsRotation/all")
			    .then((result) => {
				  this.urlList = result.list
				  for(var i = 0; i < this.urlList.length; i++){
					  this.urlList[i].url = process.env.VUE_APP_ENV + this.urlList[i].url
				  }
				  let b = JSON.stringify(this.urlList)
				  localStorage.setItem('urlList',b)
			    })
			    .catch((err) => {});
			},
		},
		
	}
</script>
<style lang="less">
.main{
	border-radius:0 80px 0 0!important
}
.paddBottom{
	padding-bottom: 0px;
}
.minHeight{
	min-height: calc(100vh - 189px)!important;
}
.el-dialog{
	// border-radius: 23px!important;
}
/deep/ .el-dialog {
  // border-radius: 23px !important;
}

.el-dialog__title {
  font-size: 24px!important;
  font-family: Alibaba Sans!important;
  font-weight: bold!important;
  color: #168ec7!important;
}

/deep/.el-dialog__header {
  padding: 38px 20px 10px7!important;
}

/deep/ .el-dialog__headerbtn {
  top: 11px!important;
  right: 16px!important;
}

/deep/ .el-dialog__body {
  padding: 38px 40px!important;
  color: #606266!important;
  font-size: 14px!important;
  word-break: break-all!important;
}

.fade-enter {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
body {
  margin: 0;
}
.flexCenter {
  display: flex;
  justify-content: center;
}
.cancel {
  width: 83px;
  height: 33px;
  background: #e6ebf3;
  border-radius: 17px;
  font-family: Alibaba Sans;
  font-weight: bold;
  color: #666666;
  line-height: 33px;
  padding: 0 !important;
  text-align: center;
  cursor: pointer;
}
.submit {
  width: 83px;
  height: 33px;
  margin-left: 10px;
  line-height: 33px;
  background: #168ec7;
  border-radius: 17px;
  font-size: 14px;
  font-family: Alibaba Sans;
  font-weight: bold;
  color: #ffffff;
  padding: 0 !important;
  text-align: center;
  cursor: pointer;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #008dc9 !important;
  border-color: #008dc9 !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #666666 !important;
}
.el-descriptions-item__label.is-bordered-label {
  background: #f7f9fb !important;
  color: #333333 !important;
  font-weight: 600;
}
.el-message--error {
  z-index: 9999999999999 !important;
}
.el-carousel__arrow--right,
.el-notification.right,
.el-message,
.el-dropdown-menu {
  z-index: 99999999999999 !important;
}
@media screen and (min-width: 900px) and (max-width: 1080px) {
  .nav_title {
    font-size: 50px !important;
  }
  .content .desc {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .nav_title {
    font-size: 34px !important;
  }
  .content .desc {
    font-size: 12px !important;
  }
  .MenuIcon {
    display: block !important;
  }
  .FixedHeader .nav,
  .position .nav {
    display: none !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 500px) {
  .MenuIcon {
    display: block !important;
    margin-right: 0px !important;
  }
  .nav_title {
    font-size: 25px !important;
  }
  .content .desc {
    font-size: 10px !important;
    width: 100% !important;
  }
  .FixedHeader .nav,
  .position .nav {
    display: none !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 380px) {
  .logoText,
  .FixedHeader .logo {
    display: none !important;
  }
  .content {
    top: 80px !important;
    left: 50px !important;
  }
  .FixedHeader .right {
    position: absolute;
    right: 0;
  }
}
@media screen and (min-width: 0px) and (max-width: 800px) {
  .show_list {
    height: 260px !important;
    display: block !important;
    .ul .li {
      justify-content: flex-start !important;
    }
    .left {
      width: 99% !important;
      padding-left: 1%;
      .h1 {
        padding-top: 10px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100% !important;
      }
    }
    .listWrapper {
      width: 100% !important;
      margin-top: 10px !important;
      .li {
        width: 100% !important;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .right {
      padding-left: 1%;
      .h1 {
        text-align: left !important;
        margin-top: 10px !important;
      }
      .ul {
        margin-top: 10px !important;
      }
    }
  }
}
@media screen and (min-width: 0px) and (max-width: 1178px) {
  .oneCard {
    // height: 620px !important;
    .cardTitle {
      // width: 100% !important;
    }
    .cardDesc {
      // width: 100% !important;
    }
    .oneCardCont {
      // display: block !important;
      // height: 620px !important;
    }
    .change_time {
      justify-content: flex-start !important;
    }
    .char {
      // margin-left: 27px;
    }
  }
  .twoCard {
    display: block !important;
    // height: 780px !important;
    .rightBg {
      margin-left: 27px !important;
    }
  }
  .threeCard {
    display: block !important;
    // height: 560px !important;
    .Progress {
      margin-right: 34px !important;
    }
    .lineName {
      width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .cardButton {
      margin-top: 40px !important;
    }
  }
}
@media screen and (min-width: 1177px) and (max-width: 1900px) {
  .lineName {
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 1900px) {
  .el-collapse-item__header {
    display: block !important;
    text-align: left;
  }
  .cardTitle {
    width: 100% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-collapse-item__header.is-active {
    width: 86% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .li .el-collapse-item__header {
    width: 86% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 960px) {
  .twoCard,
  .oneCard,
  .threeCard,
  .fourCard {
    width: 80% !important;
  }
}
@media screen and (min-width: 540px) and (max-width: 610px) {
  .content {
    left: 7% !important;
  }
}
@media screen and (max-width: 540px) {
  .content {
    left: 2% !important;
    .nav_title {
      font-size: 26px !important;
    }
    .desc {
      width: 100% !important;
      font-size: 10px !important;
    }
  }
  .h1Desc {
    width: 100% !important;
    font-size: 10px !important;
  }
}
@media screen and (max-width: 860px) {
  .lineLi {
    .lvse,
    .lanse,
    .zise,
    .shenzi,
    .caolv,
    .chengse {
      margin-right: 30px !important;
    }
  }
  .lineName {
    width: 250px !important;
  }
  .boxContent .h1Title {
    width: 100% !important;
    font-size: 35px !important;
    height: auto !important;
  }
}
@media screen and (max-width: 475px) {
  .lineName {
    width: 150px !important;
  }
  .twoCard,
  .oneCard,
  .threeCard,
  .fourCard {
    width: 98% !important;
  }
}
</style>
