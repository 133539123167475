import { render, staticRenderFns } from "./showEnd.vue?vue&type=template&id=d22036d0&scoped=true"
import script from "./showEnd.vue?vue&type=script&lang=js"
export * from "./showEnd.vue?vue&type=script&lang=js"
import style0 from "./showEnd.vue?vue&type=style&index=0&id=d22036d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d22036d0",
  null
  
)

export default component.exports