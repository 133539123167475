<template>
  <div class="footer">
    <div class="show_list">
      <div class="left">
        <div class="h1 bot-dis" @click="goSearch()" style="cursor: pointer;width: 400px;">
          <img src="@/assets/bot-icon1.png" class="bot-icon" />Institute of High Energy Physics
        </div>
        <div
          class="h1 bot-dis"
          @click="goSearchTwo()"
          style="margin-top: 6px;cursor: pointer;width: 400px;"
        >
          <img src="@/assets/bot-icon2.png" class="bot-icon" />
          National HEP Data Center
        </div>
        <div class="listWrapper">
          <!-- <div class="li">
            <div class="img2 img"></div>
            <p>Maintenance: {{maintenance}}</p>
          </div>-->
          <div class="li">
            <div class="img3 img"></div>
            <!-- <i class="el-icon-location-outline"></i> -->
            <p>Postal Code:{{postalCode}}</p>
          </div>
          <div class="li">
            <div class="img4 img"></div>
            <!-- <i class="el-icon-location-outline"></i> -->
            <p>Telephone: {{telephone}}</p>
          </div>
          <div class="li">
            <div class="img5 img"></div>
            <!-- <i class="el-icon-location-outline"></i> -->
            <p>Email: {{email}}</p>
          </div>
          <div class="li" style="width: 500px;">
            <div class="img1 img"></div>
            <p>Address: {{address}}</p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="h1" style="opacity: 0;margin-top: 5px">Other Useful Links</div>
        <div class="h1">Other Useful Links</div>
        <div class="ul">
          <!-- <div class="li" style="opacity: 0;">
            <span></span>
            <p>{{linkName?linkName:'NO NAME'}}</p>
          </div>-->
          <div class="li">
            <span></span>
            <p class="cursor" @click="link('link1')">{{linkName?linkName:'NO NAME'}}</p>
          </div>
          <div class="li">
            <span></span>
            <p class="cursor" @click="link('link2')">{{linkName2?linkName2:'NO NAME'}}</p>
          </div>
          <div class="li">
            <span></span>
            <p>
              Courtesy of the original authors and platforms who produced and distributed these information. See more in
              <span
                class="lanse cursor"
                @click="goAbout"
              >Disclaimer</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="number">
      <div>{{copyright}}</div>
      <div>
        <a class="tx" href="https://beian.miit.gov.cn/" target="_black">备案序号：京 ICP 备 05002790 号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "showEnd",
  // data() {},
  data() {
    return {
      indexData2: {},
      address: "",
      maintenance: "",
      postalCode: "",
      telephone: "",
      email: "",
      linkName: "",
      copyright: "",
      linkName2: ""
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    link(e) {
      if (e == "link1") {
        if (this.indexData2.linkUrl.description) {
          window.open(this.indexData2.linkUrl.description);
        }
      } else {
        if (this.indexData2.linkUrl2.description) {
          window.open(this.indexData2.linkUrl2.description);
        }
      }
    },
    goAbout() {
      this.$router.push("/about");
    },
    goSearch() {
      window.open("http://english.ihep.cas.cn/");
    },
    goSearchTwo() {
      window.open("https://www.nhepsdc.cn/");
    },
    getData() {
      this.$fetchGet("/cms-middle/cmsSite/config?type=basic")
        .then(result => {
          this.indexData2 = result.config;
          this.address = this.indexData2.address.description;
          this.maintenance = this.indexData2.maintenance.description;
          this.postalCode = this.indexData2.postalCode.description;
          this.telephone = this.indexData2.telephone.description;
          this.email = this.indexData2.email.description;
          this.linkName = this.indexData2.linkName.description;
          this.linkName2 = this.indexData2.linkName2.description;
          this.copyright = this.indexData2.copyright.description;
        })
        .catch(err => {});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lanse {
  text-decoration: underline;
  font-weight: 700;
}
.footer {
  width: 100%;
  position: relative;
  /* bottom: 0; */
  z-index: 99;
}
.show_list {
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  height: 149px;
  font-size: 11px;
  align-items: center;
  background: #396592;
  padding-top: 12px;
}
.img {
  width: 10px;
  height: 10px;
}
.img1 {
  width: 9px;
  height: 10px;
  background: url(@/assets/slices_slices/1.png) no-repeat center center;
  background-size: 100% 100%;
}
.img2 {
  width: 11px;
  height: 10px;
  background: url(@/assets/slices_slices/2.png) no-repeat center center;
  background-size: 100% 100%;
}
.img3 {
  background: url(@/assets/slices_slices/3.png) no-repeat center center;
  background-size: 100% 100%;
}
.img4 {
  background: url(@/assets/slices_slices/4.png) no-repeat center center;
  background-size: 100% 100%;
}
.img5 {
  background: url(@/assets/slices_slices/5.png) no-repeat center center;
  background-size: 100% 100%;
}
.h1 {
  font-size: 18px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
}
.left {
  /* width: 600px; */
  width: 30%;
}
.li p {
  margin: 0;
  margin-left: 5px;
}
.listWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
}
.listWrapper .li {
  width: 300px;
  display: flex;
  align-items: center;
  font-size: 10px;
  height: 24px;
}
.right {
  height: 100px;
  width: 700px;
}
.right .h1 {
  text-align: right;
}
.right .ul {
  margin-top: 6px;
  width: 100%;
}
.right-bot {
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
}
.ul .li {
  display: flex;
  height: 24px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.ul .li span {
  width: 4px;
  height: 4px;
  /* background: #fff; */
}
.number {
  background: #325b87;
  color: #ffffff;
  text-align: center;
  font-size: 11px;
  font-family: Arial;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tx {
  color: #ffffff;
}
.cursor {
  cursor: pointer;
}
.bot-icon {
  width: 30px;
  height: 30px;
}
.bot-dis {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
}
</style>
