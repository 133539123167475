<template>
  <div class="about">
    <router-view />
    <show-end></show-end>
  </div>
</template>
<script>
import showEnd from "../components/footer/showEnd";
export default {
  name: "indexPage",
  components: { showEnd },
  // data() {},
};
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?38ca5737e07fab0588b9e3db58b2eba0";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

</script>
¸
