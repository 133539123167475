import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'indexMaster',
    component: IndexView,
    redirect: 'indexPage',
    children: [
      {
        path: 'indexPage',
        component: () => import('../components/indexPage.vue'),
        meta: {
          title: 'HOME',
          requiresAuth: false,
		  keepAlive:true
        }
      },
      {
        path: 'message',
        component: () => import('../components/message.vue'),
        meta: {
          title: 'MESSAGE',
          requiresAuth: false,
        },
      },
      {
        path: 'events',
        component: () => import('../components/subEvents.vue'),
        meta: {
          title: 'EVENTS',
          requiresAuth: false,
        }
      },
      {
        path: 'submit',
        component: () => import('../components/submit.vue'),
        meta: {
          title: 'SUBMIT',
          requiresAuth: false,
        }
      },
      {
        path: 'subscribe',
        component: () => import('../components/subscribe.vue'),
        meta: {
          title: 'SUBSCRIBE',
          requiresAuth: true
        }
      },
      {
        path: 'PersonalCenter',
        component: () => import('../components/PersonalCenter.vue'),
        meta: {
          title: 'PersonalCenter',
          requiresAuth: true
        }
      },
      {
        path: 'about',
        component: () => import('../components/about.vue'),
        meta: {
          title: 'ABOUT',
          desc: 'ABOUT',
          requiresAuth: false
        }
      },
	  {
	    path: 'eventsDetail',
	    component: () => import('../components/infoPage/eventsDetail.vue'),
	    meta: {
	      title: 'EVENTS',
	      desc: 'Event Detail',
	      requiresAuth: false
	    }
	  },
      {
        path: 'Notice',
        component: () => import('../components/infoPage/Notice.vue'),
        meta: {
          title: 'MESSAGE',
          desc: 'Notice Detail',
          requiresAuth: false,
        }
      },
      {
        path: 'Circulars',
        component: () => import('../components/infoPage/Circulars.vue'),
        meta: {
          title: 'MESSAGE',
          desc: 'Circulars Detail',
          requiresAuth: false,
        }
      },
      {
        path: 'Atel',
        component: () => import('../components/infoPage/atel.vue'),
        meta: {
          title: 'MESSAGE',
          desc: 'ATel Detail',
          requiresAuth: false,
        }
      },
      {
        path: 'Customize',
        component: () => import('../components/infoPage/Customize.vue'),
        meta: {
          title: 'MESSAGE',
          desc: 'Customize Detail',
          requiresAuth: false,
        }
      },
      {
        path: 'submitList',
        component:()=>import('../components/submitList.vue'),
        meta: {
          title: 'SUBMITLIST',
          desc: 'SubmitList Info',
          requiresAuth: false
        }
      },
      {
        path: 'viewDataInfo',
        component:()=>import('../components/viewDataInfo.vue'),
        meta: {
          title: 'VIEWDATAINFO',
          desc: 'Submit a data display',
          requiresAuth: false
        }
      },
      {
        path: 'showSubmitInfo',
        component:()=>import('../components/showSubmitInfo.vue'),
        meta: {
          title: 'SHOWSUBMITINFO',
          desc: '',
          requiresAuth: false
        }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    //判断是否有标题
	if(localStorage.getItem('metaTitle')){
		document.title = localStorage.getItem('metaTitle')
	}else{
		document.title = to.meta.title;
	}
  }
    console.log("title",document.title)
  // 通过requiresAuth判断当前路由导航是否需要登录
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = localStorage.getItem('code')
      console.log("token",token)
    // 若需要登录访问，检查是否为登录状态
    if (!token) {
      // alert('请先登录后查看')
	  
      // next({
      //   path: '/login',
      //   query: { redirect: to.fullPath }
      // })
    } else {
      next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})


export default router
